/* eslint-disable sonarjs/no-identical-functions */
import 'keen-slider/keen-slider.min.css';

import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import { useKeenSlider } from 'keen-slider/react';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { BsArrowLeftShort, BsArrowRightShort, BsSearch } from 'react-icons/bs';

// @ts-ignore
import { Layout, SEO } from '../../components';
import { BlogPost } from '../../components/blog-post';
import { HeroNav } from '../../components/blog/blog-nav';

function preventNavigation(event) {
  // Center point of the touch area
  const touchXPosition = event.touches[0].pageX;
  // Size of the touch area
  const touchXRadius = event.touches[0].radiusX || 0;

  // We set a threshold (10px) on both sizes of the screen,
  // if the touch area overlaps with the screen edges
  // it's likely to trigger the navigation. We prevent the
  // touchstart event in that case.
  if (
    touchXPosition - touchXRadius < 10 ||
    touchXPosition + touchXRadius > window.innerWidth - 10
  )
    event.preventDefault();
}

function Carousel({ children, title }) {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [isMounted, setIsMounted] = React.useState(false);
  // @ts-ignore
  const sliderContainerRef = React.useRef(null);

  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 3,
    duration: 1500,
    spacing: 48,
    mounted: () => setIsMounted(true),
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    breakpoints: {
      '(min-width: 678px) and (max-width: 1080px)': {
        slidesPerView: 2,
      },
      '(max-width: 677px)': {
        slidesPerView: 1,
      },
    },
  });

  // Stop the history navigation gesture on touch devices
  React.useEffect(() => {
    // @ts-ignore
    sliderContainerRef.current?.addEventListener(
      'touchstart',
      preventNavigation,
      { passive: true }
    );
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // @ts-ignore
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sliderContainerRef.current?.removeEventListener(
        'touchstart',
        preventNavigation
      );
    };
  }, []);

  // Allow control of carousel with keyboard when focused
  function handleKeyDown(e) {
    if (e.key === 'ArrowLeft') slider.prev();
    if (e.key === 'ArrowRight') slider.next();
  }

  return (
    <div>
      <h2 className="text-4xl leading-tight md:px-8 text-brand-blue font-display">
        {title}
      </h2>
      <div
        ref={sliderContainerRef}
        onKeyDown={(e) => handleKeyDown(e)}
        tabIndex={0}
        role="region"
        className="relative mt-2 md:px-8 bg-blue-dark focus:z-10"
      >
        <ul
          // @ts-ignore
          ref={sliderRef}
          className="pb-12 transition-opacity duration-150 keen-slider"
          style={{ opacity: isMounted ? 1 : 0 }}
        >
          {React.Children.map(children, (child) => {
            // Add the keen-slider__slide className to children
            if (React.isValidElement(child)) {
              return {
                ...child,
                props: {
                  ...child.props,
                  className: `${
                    child.props.className
                      ? `${String(child.props.className)} `
                      : ''
                  }keen-slider__slide`,
                },
              };
            }
            return child;
          })}
        </ul>
        {slider && (
          <div className="absolute flex justify-between w-20 right-2 md:block md:w-auto -top-7 md:static">
            <button
              type="button"
              className="left-0 transform -translate-y-1/2 md:absolute md:-left-6 top-1/2 focus:outline-white"
            >
              <BsArrowLeftShort
                // @ts-ignore
                onClick={(e) => e.stopPropagation() || slider.prev()}
                // @ts-ignore
                disabled={currentSlide === 0}
                className="text-5xl"
              />
            </button>

            <button
              type="button"
              className="right-0 transform -translate-y-1/2 md:absolute md:-right-6 top-1/2 focus:outline-white"
            >
              <BsArrowRightShort
                // @ts-ignore
                onClick={(e) => e.stopPropagation() || slider.next()}
                // @ts-ignore
                disabled={currentSlide === slider.details().size - 1}
                className="text-5xl"
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

function BlogPostPage({ data: { allSanityPost, packingPosts, movingPosts } }) {
  const location = useLocation();

  const categoryParam = new URLSearchParams(location.search).get('category');
  const searchParam = new URLSearchParams(location.search).get('search');
  /**
   * Filter posts from search input
   */
  const [searchQuery, setSearchQuery] = useState(searchParam || '');
  const [posts, setPosts] = useState(allSanityPost.nodes);

  // Filter search results from search results
  useMemo(() => {
    const filteredPosts = allSanityPost.nodes.filter(
      (node) =>
        node.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        node.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
        node.categories.find(({ title }) =>
          title.toLowerCase().includes(searchQuery.toLowerCase())
        )
    );
    setPosts(filteredPosts);
  }, [allSanityPost.nodes, searchQuery]);

  /**
   * Filter posts from select menu
   */
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState(categoryParam || 'all');
  // Get unique categories
  useMemo(() => {
    const cat = [];
    // Push all category titles to cat array
    posts.map((post) =>
      post.categories.map((category) => cat.push(category.title))
    );
    // Filter duplicates and sort alphabetically
    // @ts-ignore
    setCategories([...new Set(cat)].sort((a, b) => a.localeCompare(b)));
  }, [posts]);

  // Filter by tag
  const filtered = useMemo(
    () =>
      posts.filter((post) => {
        if (filter !== 'all') {
          return post.categories.some(({ title }) => title === filter);
        }
        return posts;
      }),
    [filter, posts]
  );

  return (
    <Layout formName="blog-page-bottom-form" formTitle="Contact us today">
      <SEO title="Interstate Removalists - Blog | Frontline Removals" />
      <header className="w-full px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <HeroNav />
      </header>
      <article className="w-full px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <p className="text-6xl font-semibold leading-none text-center uppercase font-display text-brand-blue">
          <span className="">Learn more </span>
          <span className="inline-block text-brand-orange">About us</span>
        </p>
        <div className="grid gap-4 mt-12 md:grid-cols-2">
          <div className="flex items-center px-3 bg-white border border-black border-solid rounded-md">
            <BsSearch />
            <label htmlFor="search-input" className="block w-full">
              <input
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                type="search"
                name="search"
                placeholder="Search all blog posts"
                id="search-input"
                className="w-full py-1 pl-3 placeholder-black bg-white border-none outline-none form-input rounded-r-md"
              />
            </label>
          </div>

          <label htmlFor="tag-filter" className="relative">
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              id="tag-filter"
              className="w-full py-2 pl-3 pr-8 leading-relaxed bg-white border border-black rounded-md form-select focus:outline-white"
            >
              <option value="all">Select a category</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>

            <span className="absolute pr-2 bg-white right-1 top-2">
              <BiChevronDown className="text-2xl" />
            </span>
          </label>
        </div>

        {searchQuery !== '' || filter !== 'all' ? (
          <SearchResult
            posts={filtered}
            filter={filter}
            setFilter={setFilter}
          />
        ) : (
          ''
        )}
        <div className="mt-12">
          <Carousel title="Most recent">
            {allSanityPost.nodes.map((post, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index} className="flex">
                <BlogPost
                  key={post.id}
                  post={post}
                  filter={filter}
                  setFilter={setFilter}
                />
              </li>
            ))}
          </Carousel>

          <Carousel title="Packing">
            {packingPosts.nodes.map((post, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index} className="flex p-2 ">
                <BlogPost
                  key={post.id}
                  post={post}
                  filter={filter}
                  setFilter={setFilter}
                />
              </li>
            ))}
          </Carousel>

          <Carousel title="Moving">
            {movingPosts.nodes.map((post, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index} className="flex p-2 ">
                <BlogPost
                  key={post.id}
                  post={post}
                  filter={filter}
                  setFilter={setFilter}
                />
              </li>
            ))}
          </Carousel>
        </div>
      </article>
    </Layout>
  );
}

BlogPostPage.propTypes = {
  data: PropTypes.object.isRequired,
};

function SearchResult({ posts, filter, setFilter }) {
  if (posts <= 0) {
    return (
      <p className="w-full my-40 text-center max-w-none">
        No results found! Please try a different search.
      </p>
    );
  }
  return (
    <div className="mt-12 md:px-8">
      <h2 className="text-4xl leading-tight text-brand-blue font-display ">
        Search result
      </h2>
      <div className="grid w-full grid-cols-1 gap-12 mt-2 max-w-none lg:grid lg:grid-cols-3">
        {posts.map((post) => (
          <BlogPost
            key={post.id}
            post={post}
            filter={filter}
            setFilter={setFilter}
          />
        ))}
      </div>
    </div>
  );
}

SearchResult.propTypes = {
  posts: PropTypes.array.isRequired,
  filter: PropTypes.any,
  setFilter: PropTypes.func,
};

export const query = graphql`
  {
    allSanityPost(sort: { fields: _createdAt, order: DESC }) {
      nodes {
        _createdAt(formatString: "MMMM DD, YYYY")
        categories {
          title
        }
        description
        id
        mainImage {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        slug {
          current
        }
        title
      }
    }

    packingPosts: allSanityPost(
      filter: { categories: { elemMatch: { title: { eq: "packing" } } } }
    ) {
      nodes {
        _createdAt(formatString: "MMMM DD, YYYY")
        categories {
          title
        }
        description
        mainImage {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        id
        slug {
          current
        }
        title
      }
    }

    movingPosts: allSanityPost(
      filter: { categories: { elemMatch: { title: { eq: "moving" } } } }
    ) {
      nodes {
        _createdAt(formatString: "MMMM DD, YYYY")
        categories {
          title
        }
        description
        mainImage {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        id
        slug {
          current
        }
        title
      }
    }
  }
`;

export default BlogPostPage;
